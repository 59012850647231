//$body-bg: #000;
$theme-colors: (
"primary": #b4a6bf
//"primary": #570d5e 
);

//$body-bg: #eaeaea;
$dark: #d5cde2;

$container-max-widths: (
md: 720px,
xl: 960px
) !default;

body {
  background-image: url("https://dynamic.websimages.com/s/themes/52f8f39951593b6a57000010/v1.25/images/swirl-1.png");
   background-repeat: no-repeat;
   background-position: center 457px;
}

// Navbar
// Allgemein

.component_info {
	//display: block;
	display: none;
}

h1 {
	text-align: center;
	// font-family: 'Courgette', cursive;
    //font-family: 'Petit Formal Script', cursive;

    font-family: 'Quintessential', cursive;
	font-size: 3.0rem !important;
}

h2 {
	padding-top: 30px;
	color: map-get($theme-colors, "primary");
	font-family: 'Courgette', cursive;
    font-family: 'Quintessential', cursive;
}

h3 {
	padding-top: 5px;
	color: grey;
	font-size: 1.5rem !important;
}

p {
	line-height: 2.0em;
}

th {
	color: purple;
}

.contact-form div {
	margin-bottom: 15px;
}

.navbar-brand {
    font-family: 'Quintessential', cursive;
}

.nav-link {
    font-family: 'Quintessential', cursive;
}

a:link,  a:visited, a:hover, a:active {
	color: purple;
	text-decoration: none;
}

.primary-color {
	background-color: map-get($theme-colors, "primary");
	color: white;
	margin-top: 20px;
	padding-left: 10px;
	padding-right: 10px;
}


// Navigation auf Spezialseiten
.nav_image {
	background: url(https://handundfuss.erdin.dev/img/IMG_2621.jpg) no-repeat center center scroll; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_ {
	background: url(https://handundfuss.erdin.dev/img/IMG_2621.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}


.nav_image_home {
	background: url(https://handundfuss.erdin.dev/img/IMG_2621.jpg) no-repeat center center scroll; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_contact {
	background: url(https://handundfuss.erdin.dev/img/IMG_2767.jpg) no-repeat center center scroll; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_contactEnd {
	background: url(https://handundfuss.erdin.dev/img/IMG_2767.jpg) no-repeat center center scroll; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_contactError {
	background: url(https://handundfuss.erdin.dev/img/IMG_2767.jpg) no-repeat center center scroll; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_about {
	background: url(https://handundfuss.erdin.dev/img/IMG_2705.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_offers {
	background: url(https://handundfuss.erdin.dev/img/IMG_2732.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_gallery {
	background: url(https://handundfuss.erdin.dev/img/IMG_2723.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_nails {
	background: url(https://handundfuss.erdin.dev/img/banner/nails.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_feet {
	background: url(https://handundfuss.erdin.dev/img/banner/feet.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

.nav_image_teeth {
	background: url(https://handundfuss.erdin.dev/img/banner/teeth.jpg) no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 400px;
	margin-bottom: 30px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
	//border-top: 1px solid map-get($theme-colors, "primary");
}

// Home
.lead {
	font-size: 40px;
	font-style: italic;
	letter-spacing: 1px;
}

// Sidebar
.sidebar {
	// background-color: #f5f0fa;
	//background-image: linear-gradient(0deg, map-get($theme-colors, "primary") 0%, #dfd2de 50%);
	background-image: linear-gradient(0deg, #dfd2de 0%, #efe2ee 50%);
	padding: 14px 25px 1px 25px;
	margin-top: 74px;
	border-bottom: 4px solid map-get($theme-colors, "primary");
}

.sidebar > div > img {
	margin-top: -70px;
	border: 1px solid map-get($theme-colors, "primary");
	padding: 5px;
	background-color: white;
}

// Footer 
.footer {
	//background-color:map-get($theme-colors, "primary"); 
	background-image: linear-gradient(90deg, map-get($theme-colors, "primary") 0%, #dfd2de 50%);
	margin-top: 40px;
	padding-top: 30px;
	padding-bottom: 30px;
	line-height: 2em;
}


@media (max-width: 768px) {
	.sidebar > div > img {
		max-width: 200px;
	}

}

.navbar-light {
	background-image: linear-gradient(90deg, map-get($theme-colors, "primary") 0%, #dfd2de 50%);
}



// Offers

thead {
	background-color: $dark;
	color: map-get($theme-colors, "primary");
}

.zahncontainer {
	border: 1px solid map-get($theme-colors, "primary");
	margin-top: 20px;
	padding: 20px;
	padding-bottom: 0px;
}

.zahncontainer > h2 {
	padding-top: 0px;
}

.zahnpicture {
	margin-bottom: 20px;
}

th, td {
	padding: 0.75rem !important;
}



// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Import Image Gallery Stuff
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

// Import Font-Awesome
@import url('https://fonts.googleapis.com/css?family=Courgette&display=swap');

@import url('https://fonts.googleapis.com/css?family=Petit+Formal+Script|Quintessential|Tangerine&display=swap');
